<template>
  <base-web-page>
    <template v-slot:main>
        <el-main style="padding:60px 0px;margin:0 auto;width:100%;max-width:1300px;">
          <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:0px 10px;">
            <el-breadcrumb-item to="/">Home</el-breadcrumb-item>
            <el-breadcrumb-item>hotels</el-breadcrumb-item>
          </el-breadcrumb>
          <h1>Available Hotels</h1>
          <el-row>
              <el-col style="padding:10px;" :span="6" v-for="(item, index) in hotels" :key="index" :item="item">
                <hotel-item :item="item"></hotel-item>
              </el-col>
          </el-row>
          <el-divider></el-divider>
          <h1>Hotel Booking Instruction</h1>
          <div style="text-align:left; margin-bottom:10px;">1. UClub member needs to call or email hotel directly.</div>
          <div style="text-align:left; margin-bottom:10px;">2. Before checkin, UClub member needs to show the UClub membership card to the front desk</div>
          <div style="text-align:left; margin-bottom:10px;">3. Scan the UClub QR placed at the front desk for checkin confirmation, UClub points will reward after your leaving.</div>
          <div style="text-align:left;">
              <div style="font-size:14px;font-weight:bold;margin-left:70px;">UClub QR Example</div>
            <!--<el-image style="width:250px;" :src="require('../../assets/scan_example.png')"></el-image>-->
          </div>
      </el-main>
    </template>
  </base-web-page>
</template>

<script>
import BaseWebPage from '../../components/base/BaseWebPage.vue'
import ModelHotel from '../viewmodel/ModelHotel.vue'
import HotelItem from '../../components/widgets/HotelItem.vue'

export default {
    extends: ModelHotel,
    name: 'hotels',
    components: {
        BaseWebPage,
        HotelItem
    }
}
</script>

<style>

</style>