<template>
  <div></div>
</template>

<script>
import hotelApi from '../../api/hotel'

export default {
    name: 'hotels',
    data(){
        return {
            hotels: []
        }
    },
    mounted(){
        this.getHotels()
    },
    methods: {
        getHotels(){
            hotelApi.list().then(resp=>{
                this.hotels = resp
            })
        }
    }
}
</script>

<style>

</style>